<template>
  <div>
    <div class="row">
      <transition name="slide-fade" appear>
        <div class="col-lg-6 site-form">
          <h4>Datos de correo</h4>
          <h6>Para envío de notificaciones</h6>
          <form class="form-site inner" @submit.prevent="submitEmailConfig">
            <div class="form-row">
              <div class="form-holder form-holder-2" :class="{error: validation.hasError('mail_host')}">
                <fieldset>
                  <legend>Servidor</legend>
                  <input type="text" class="form-control" placeholder="mail.servidor.com" v-model="mail_host">
                </fieldset>
                <div class="error-msg mt-1 mb-0" v-if="validation.hasError('mail_host')">** {{ validation.firstError('mail_host') }}</div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-holder form-holder-2" :class="{error: validation.hasError('mail_port')}">
                <fieldset>
                  <legend>Puerto</legend>
                  <input type="text" class="form-control" placeholder="puerto de salida" v-model="mail_port">
                </fieldset>
                <div class="error-msg mt-1 mb-0" v-if="validation.hasError('mail_port')">** {{ validation.firstError('mail_port') }}</div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-holder form-holder-2" :class="{error: validation.hasError('mail_user')}">
                <fieldset>
                  <legend>Correo</legend>
                  <input type="text" class="form-control" placeholder="usuario@mail.com" v-model="mail_user" autocomplete="off">
                </fieldset>
                <div class="error-msg mt-1 mb-0" v-if="validation.hasError('mail_user')">** {{ validation.firstError('mail_user') }}</div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-holder form-holder-2">
                <fieldset>
                  <legend>Contraseña</legend>
                  <div class="input-group">
                    <input v-if="showPassword" type="text" class="form-control" placeholder="******" autocomplete="off" v-model="mail_password">
                    <input v-else type="password" class="form-control" placeholder="******" autocomplete="off" v-model="mail_password">

                    <div class="input-group-append cursor-pointer" v-if="mail_password.length > 0">
                      <span class="input-group-text">
                        <div class="toggle-show-btn" @click="toggleShow">
                          <font-awesome-icon :icon="{ prefix: 'fa', iconName: showPassword ? 'eye-slash' : 'eye' }" class="no-help"/>
                        </div>
                      </span>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="form-row">
              <div class="form-holder form-holder-2" :class="{error: validation.hasError('mail_ssl')}">
                <fieldset>
                  <legend>Conexión cifrada SSL</legend>
                  <b-form-checkbox v-model="mail_ssl">Mi servidor requiere cifrado SSL</b-form-checkbox>
                </fieldset>
                <div class="error-msg mt-1 mb-0" v-if="validation.hasError('mail_ssl')">** {{ validation.firstError('mail_ssl') }}</div>
              </div>
            </div>
            <button type="submit" class="btn btn-block btn-action mt-3 mb-4" block>
              <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
              <font-awesome-icon v-if="!loading" :icon="{ prefix: 'fa', iconName: 'save', }" class="icon btn-icon" />Actualizar datos
            </button>
          </form>
        </div>
      </transition>
      <transition name="slide-fade" appear>
        <div class="col-lg-6 site-form">
          <h4>Notificaciones de Perfil</h4>
          <h6>Enviar notificación cuando el proveedor cargó un archivo a su perfil</h6>
          <label for="mail-tags">Indique los correos a los que desea notificar:</label>
          <b-form-tags
            remove-on-delete
            input-id="mail-tags" placeholder="Escribir correo electrónico y presione enter" separator=",;"
            addButtonText="Agregar"
            duplicateTagText="Correo duplicado"
            invalid-tag-text="Correo NO válido"
            v-model="emails_notif_perfil"
            @input="updateEmailsNotifPerfil"
            :tag-validator="mailValidator"></b-form-tags>
        </div>
      </transition>
      <transition name="slide-fade" appear>
        <div class="col-lg-12 site-form">
          <h4>Envío de notificaciones</h4>
          <h6>Dias de envío de notificaciones a proveecores.</h6>
          <div class="form-row" style="align-items: baseline">
            <span v-for="dia in dias_notif" :key="dia.orden">
              <b-form-checkbox class="mr-2" v-model="dia.activo">{{ dia.nombre }}</b-form-checkbox>
            </span>
            <div>
              <b-form-timepicker class="ml-2" size="sm" locale="es" placeholder="Horario" label-close-button="Cerrar" minutes-step="60" v-model="hora_notif"></b-form-timepicker>
            </div>
          </div>
          <button class="btn btn-action mt-3 mb-4" @click="updateDiasNotif">
            <font-awesome-icon v-if="loadingDiasNotif" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
            <font-awesome-icon v-if="!loadingDiasNotif" :icon="{ prefix: 'fa', iconName: 'save', }" class="icon btn-icon" /> Guardar
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';

import { mapState } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  components: {},
  data() {
    return {
      loading: false,
      loadingDiasNotif: false,
      mail_host: '',
      mail_port: 465,
      mail_user: '',
      mail_password: '',
      mail_ssl: true,
      showPassword: false,
      emails_notif_perfil: [],
      tasks: [],
      cronexp: '0 9 * * 1,2,3,4,5', // por defecto, de lun a vier a las 9am
      hora_notif: '09:00',
      dias_notif: [
        { orden: 1, nombre: 'Lunes', activo: true },
        { orden: 2, nombre: 'Martes', activo: true },
        { orden: 3, nombre: 'Miercoles', activo: true },
        { orden: 4, nombre: 'Jueves', activo: true },
        { orden: 5, nombre: 'Viernes', activo: true },
        { orden: 6, nombre: 'Sabado', activo: false },
        { orden: 7, nombre: 'Domingo', activo: false },
      ],
    };
  },
  validators: {
    mail_host(value) {
      return Validator.value(value)
        .required('Campo requerido')
        .lengthBetween(5, 100, 'Longitud debe ser entre 5 y 100 caracteres');
    },
    mail_port(value) {
      return Validator.value(value)
        .required('Campo requerido')
        .integer('Campo numérico');
    },
    mail_user(value) {
      return Validator.value(value)
        .required('Campo requerido')
        .email('Formato de correo no válido')
        .lengthBetween(5, 100, 'Longitud debe ser entre 5 y 100 caracteres');
    },
    mail_ssl(value) {
      return Validator.value(value)
        .required('Campo requerido')
        .in([1, 0, true, false], 'Solo se acepta valor verdadero o falso');
    },
  },
  computed: {
    ...mapState(['aggrid']),
  },
  mounted() {
    this.getData();
  },
  methods: {
    // Metodo para actualizar datos de la tabla (server - side)
    getData() {
      apiClient.get('/configuraciones')
        .then((res) => {
          this.mail_host = res.data?.config?.mail_host || '';
          this.mail_port = res.data?.config?.mail_port || '';
          this.mail_user = res.data?.config?.mail_user || '';
          this.mail_ssl = Boolean(res.data?.config?.mail_ssl) || false;
          this.emails_notif_perfil = res.data?.emailsNotif?.map((e) => e.Correo);
          this.tasks = res.data?.tasks;
          this.setTaskNotifProvs(this.tasks);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setTaskNotifProvs(tasks) {
      const task = tasks.filter((t) => t.task_name === 'notificar_proveedores')[0];
      const hora = task?.cron_expression.split(' ')[1];
      const dias = task?.cron_expression.split(' ')[4];
      this.hora_notif = `${hora}:00`;
      this.dias_notif.forEach((d, i) => { this.dias_notif[i].activo = dias.includes(d.orden); });
    },
    submitEmailConfig() {
      this.$validate()
        .then((success) => {
          if (success) {
            this.loading = true;
            apiClient.put('/configuraciones', {
              mail_host: this.mail_host,
              mail_port: this.mail_port,
              mail_user: this.mail_user,
              mail_password: this.mail_password,
              mail_ssl: this.mail_ssl,
            })
              .then(() => {
                this.getData();
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
                this.getData();
              });
          }
        });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    updateDiasNotif() {
      let dias = this.dias_notif.filter((d) => d.activo === true);
      dias = dias.map((d) => d.orden);
      dias = dias.join(',');
      const hora = this.hora_notif?.split(':')[0] || 9;
      this.cronexp = `0 ${hora} * * ${dias}`; // por defecto, de lun a vier a las 9am
      this.updateNotificacionProveedores();
    },
    updateNotificacionProveedores() {
      this.loading = true;
      apiClient.put('/tasks', {
        name: 'notificar_proveedores',
        expression: this.cronexp,
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.getData();
        });
    },
    updateEmailsNotifPerfil(emails) {
      apiClient.put('/correos-notif-perfil', { emails })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.getData();
        });
    },
    mailValidator(mail) {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailPattern.test(mail);
    },
  },
  watch: {
    mail_user() {
      this.$nextTick(() => {
        this.mail_user = this.mail_user.replace(/\s+/g, '');
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
